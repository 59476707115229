import datasetSchoolYear from '@/datasets/schoolYear'
import datasetMonth from '@/datasets/month'
import datasetYear from '@/datasets/year'
import i18n from '@/plugins/i18n'
import moment from 'moment/moment'

export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.name.student"),
          value: 'countStudent',
        },
        {
          text: i18n.t("model.prop.isActive"),
          value: 'isActive',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Student',
        label: i18n.t("model.name.students"),
      },
      // {
      //   key:'Teacher',
      //   label:'teachers',
      // },
      // {
      //   key: 'EventOnClassroom',
      //   label: i18n.t("model.name.events"),
      // },
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        // {
        //   text: 'Invitation',
        //   value: 'classroomMessageInvitation',
        // },
        {
          text: i18n.t("model.prop.isActive"),
          value: 'isActive',
        },
        {
          text:  i18n.t("string.self_registration"),
          value: 'isEnable',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'select',
        key: 'schoolYear',
        name: i18n.t("model.prop.schoolYear"),
        options: datasetSchoolYear,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },

      {
        type: 'select',
        key: 'month',
        name: 'Month',
        options: datasetMonth,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 3,
      },
      {
        type: 'select',
        key: 'year',
        name: 'Year',
        options: datasetYear,
        optionText: '',
        optionValue: '',
        defaultValue: parseInt(moment().format('YYYY')),
      },
      {
        type: 'radio',
        key: 'isActive',
        name: i18n.t("model.prop.isActive"),
        defaultValue: true,
      },
      {
        type: 'radio',
        key: 'enableWalkin',
        name: i18n.t("view.PageBackendClassroom.self_registration"),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit: {
    fields: [
      {
        type: 'model',
        key: 'institutionId',
        name: 'Institution',
        model: 'institution',
        server:'sso',
      },
      {
        type: 'select',
        key: 'schoolYear',
        name: i18n.t("model.prop.schoolYear"),
        options: datasetSchoolYear,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },

      {
        type: 'select',
        key: 'month',
        name: 'Month',
        options: datasetMonth,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 3,
      },
      {
        type: 'select',
        key: 'year',
        name: 'Year',
        options: datasetYear,
        optionText: '',
        optionValue: '',
        defaultValue: parseInt(moment().format('YYYY')),
      },
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'radio',
        key: 'isActive',
        name: i18n.t("model.prop.isActive"),
      },
      {
        type: 'radio',
        key: 'enableWalkin',
        name: i18n.t("view.PageBackendClassroom.self_registration"),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  delete: true,
}