export default [
  {
    id: 1,
    name: "Public",
    value: "public",
  },
  {
    id: 2,
    name: "Private",
    value: "private",
  },
  {
    id: 3,
    name: "Internal",
    value: "internal",
  },
]