import Moderator from '@/models/bread/Moderator/institution'

export default {
  key:'Institution',
  server:'sso',
  name:{
    singular:'Institution',
    plural:'Institutions',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}