import Moderator from '@/models/bread/Moderator/institutionClassroom'

export default {
  key:'Classroom',
  name:{
    singular:'Classroom',
    plural:'Classrooms',
  },
  parents:[
    'Account',
    'Institution'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}