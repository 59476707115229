import i18n from "@/plugins/i18n";
import datasetType from '@/datasets/schoolType'
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: "name",
        },
        {
          text: "Group Code",
          value: "code",
        },
        {
          text: "Country Code",
          value: "countryCode",
        },
        {
          text: i18n.t("model.prop.town"),
          value: "town",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Participate",
          value: "isParticipating",
        },
        {
          text: i18n.t("model.name.teachers"),
          value: "countTeacher",
        },
        {
          text: i18n.t("model.name.classrooms"),
          value: "countClassroom",
        },
        {
          text: i18n.t("model.name.students"),
          value: "countStudent",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Classroom",
        label: "Classrooms",
      },
      {
        key: "Teacher",
        label: "Teachers",
      },
      {
        key: "Event",
        label: "Events",
      },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.logo"),
          value: 'logoUrl',
        },
        {
          text: i18n.t("model.prop.name"),
          value: "name",
        },
        {
          text: "Group Code",
          value: "code",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Country Code",
          value: "countryCode",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: i18n.t("model.prop.town"),
          value: "town",
        },
        {
          text: "Postcode",
          value: "postcode",
        },
        {
          text: "State",
          value: "state",
        },
        {
          text: "Participate",
          value: "isParticipating",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        key: "name",
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'model',
        key: 'countryCode',
        name: 'Country',
        model: 'country',
        optionText: 'name',
        server:'sso',
        optionValue: 'countryCode',
        defaultValue:'MYS'
      },
      {
        type: "string",
        key: "address",
        name: "Address",
      },
      {
        type: "string",
        key: "town",
        name: i18n.t("model.prop.town"),
      },
      {
        type: "string",
        key: "postcode",
        name: "Postcode",
      },
      {
        type: 'model',
        key: 'state',
        name: 'State',
        model: 'state',
        optionText: 'name',
        server:'sso',
        optionValue: 'value',
      },
      {
        type: 'select',
        key: 'type',
        name: "Type",
        options: datasetType,
        optionText: 'name',
        optionValue: 'value',
        defaultValue: 'public',
      },
    ],
    rules: {
      name: [(v) => !!v || i18n.t("rules.name")],
      town: [(v) => !!v || 'Town is requir'],
    },
  },
  edit: {
    fields: [
      {
        type: "string",
        key: "name",
        name: "Name",
      },
      {
        type: "string",
        key: "code",
        name: i18n.t("model.prop.code"),
      },
      {
        type: 'model',
        key: 'countryCode',
        name: 'Country',
        model: 'country',
        optionText: 'name',
        server:'sso',
        optionValue: 'countryCode',
      },
      {
        type: "string",
        key: "address",
        name: "Address",
      },
      {
        type: "string",
        key: "town",
        name: i18n.t("model.prop.town"),
      },
      {
        type: "string",
        key: "postcode",
        name: "Postcode",
      },
      {
        type: 'model',
        key: 'state',
        name: 'State',
        model: 'state',
        optionText: 'name',
        server:'sso',
        optionValue: 'value',
      },
      {
        type: 'select',
        key: 'type',
        name: "Type",
        options: datasetType,
        optionText: 'name',
        optionValue: 'value',
        defaultValue: 'public',
      },
      {
        type: 'radio',
        key: 'isActive',
        name: i18n.t("model.prop.isActive"),
      },
      {
        type: 'radio',
        key: 'isParticipating',
        name: 'Participate',
      },
    ],
    rules: {
      name: [(v) => !!v || "Name is required"],
    },
  },
  delete: true,
};
